import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Container } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Seo from '../../components/seo'
import Layout from '../../components/layout'
import LandingPageForm from '../../components/common/landing-page-form'
import useBetterMediaQuery from '../../components/hooks/use-better-media-query'
import queryString from 'query-string'
import VideoPlayer from '../../components/common/video-player'
import LocatorCallOut from '../../components/sections/locator-call-out'
import LandingCTA from '../../components/sections/landing-cta'
import CutOverviewLP from '../../components/sections/parts/cut-overview-lp'

const OverviewLP = ({ location }) => {

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    function getNavHeight() {
      return document.querySelector('#navBar').offsetHeight - 1
    }

    // scrolltrigger for sticky nav
    ScrollTrigger.create({
      trigger: '#lpNav',
      start: `top top+=${getNavHeight()}px`,
      end: 'top bottom',
      endTrigger: '#footer',
      pin: true,
      pinSpacing: false,
    })

    return () => {
      // We kill all scroll triggers so the next pages can recreate them.
      const triggers = ScrollTrigger.getAll()
      if (triggers) {
        triggers.forEach((trigger) => {
          trigger.kill()
        })
      }
    }

  }, [])

  const { utm_content } = queryString.parse(location.search)

  const seoTitle = utm_content && utm_content.includes('protein')
    ? '18g of protein'
    : 'MushroomRoot™';

  const pageTitle = utm_content && utm_content.includes('protein')
    ? '18g of protein caught your eye, eh?'
    : 'MushroomRoot™ caught your eye, eh?';

  const meta = [
    {
      name: 'og: title',
      content: seoTitle,
    },
    {
      name: 'og:description',
      content: pageTitle,
    },
  ]

  

  const cuts = useStaticQuery(graphql`
    query AllCutsLP {
      allWpCut(sort: {order: ASC, fields: menuOrder}) {
        nodes {
          title
          slug
          cutsFields {
            accentColor
            cuttingBoardImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, width: 813, aspectRatio: 1, layout: CONSTRAINED)
                }
              }
            }
            featuredRecipe {
              ... on WpRecipe {
                title
                slug
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 600, aspectRatio: 1.5)
                      }
                    }
                  }
                }
              }
            }
          }
          nutritionFacts {
            panelTop {
              amount
              fieldGroupName
              item
              percentDailyValue
            }
          }
          featuredImage {
            node {
              slug
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 90
                    width: 636
                    height: 636
                    placeholder: BLURRED
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  const isMobile = useBetterMediaQuery('(max-width: 767px)')

  return (
    <Layout>
      <Seo meta={meta} title={seoTitle} />
      <header id="landingHero" className="bg-light py-1 py-lg-3 py-xxl-5">
        <Container>
          <Row className="align-items-center">
            <Col md="7" className="align-self-center">
              <Container className="py-1 py-lg-0">
                <h1 className="text-primary fw-bold mb-1 mb-lg-3">
                  {pageTitle}
                </h1>
                {
                  isMobile &&
                  <StaticImage
                    src="../../images/hero/crispy-cutlet-packaging-0823.png"
                    quality={95}
                    formats={['AUTO', 'WEBP']}
                    alt="Meati™ Crispy Cutlet"
                    placeholder="blurred"
                    layout="fullWidth"
                    className='me-n6 ms-2 mb-1'
                  />
                }
                <div id="lpSignup">
                  <p className='text-primary ff-serif mb-12'>
                    Be the first to hear about exclusive offers and events.
                  </p>
                  <p className='text-primary ff-serif mb-1'>
                    Sign up for updates here and never miss a beat.
                  </p>
                  <LandingPageForm />
                </div>
                
              </Container>
            </Col>
            {!isMobile &&
              <Col md="5">
                <StaticImage
                  src="../../images/hero/crispy-cutlet-packaging-0823.png"
                  quality={95}
                  formats={['AUTO', 'WEBP']}
                  alt="Meati™ Crispy Cutlet"
                  placeholder="blurred"
                  layout="fullWidth"
                  className='me-n10 ms-2'
                />
              </Col>
            }
          </Row>
        </Container>
      </header>
      <nav id="lpNav" className='border border-2 border-primary bg-white py-1' style={{zIndex:1}}>
        <Container>
          <ul className="list-unstyled mb-0 d-flex justify-content-center text-decoration-underline text-primary fw-bold h6 text-center align-items-center">
            <li>
              <AnchorLink 
                to="#cuts" 
                title="our cutlets and steaks" 
                className="px-12 px-md-1 px-lg-2 px-xxl-3 border-end border-primary border-2 d-block"
                stripHash
              />
            </li>
            <li>
              <AnchorLink
                to="#craft"
                title="how we craft them"
                className='px-12 px-md-1 px-lg-2 px-xxl-3 border-end border-primary border-2 d-block'
                stripHash
              />
            </li>
            <li>
              <AnchorLink
                to="#locatorCallOut"
                title="where to find them"
                className='px-12 px-md-1 px-lg-2 d-block'
                stripHash
              />
            </li>
          </ul>
        </Container>
      </nav>
      <section id="LPCuts">
        <Container fluid className='px-0'>
          {cuts.allWpCut.nodes.map((node, index) => <CutOverviewLP key={index} node={node} />)}
        </Container>
      </section>
      <section className='py-1 bg-info' id="craft">
        <Container fluid>
          <VideoPlayer url='https://player.vimeo.com/video/815104796?h=d80e2b384a' containerClass="rounded-4 overflow-hidden" ratio="16x9" buttonColor="light" progressColor="warning" />
        </Container>
      </section>
      <LocatorCallOut />
      <LandingCTA />
    </Layout>
  )
}

export default OverviewLP